const handleScrollEvent = () => {
    const scrollTop = document.getElementsByClassName('scroll-top');

    if(scrollTop.length){
        if(100 < document.documentElement.scrollTop){
            scrollTop[0].classList.remove('d-none');
            scrollTop[0].classList.add('flex');
        } else {
            scrollTop[0].classList.remove('flex');
            scrollTop[0].classList.add('d-none');
        }

        const scrollToTop = () => {
            document.documentElement.classList.remove('transition-duration');
            document.documentElement.classList.add('transition-duration');
            document.documentElement.scrollTop = 0;
        };

        scrollTop[0].removeEventListener('click', scrollToTop);
        scrollTop[0].addEventListener('click', scrollToTop);
    } 
};

document.onreadystatechange = function () {
    document.removeEventListener('scroll', handleScrollEvent);
    document.addEventListener('scroll', handleScrollEvent);
};
